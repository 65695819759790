import React from "react";
import { graphql } from "gatsby";
import { MDXRenderer } from "gatsby-plugin-mdx";
import { MDXProvider } from "@mdx-js/react";
import { getImage, GatsbyImage } from "gatsby-plugin-image";
import { GatsbySeo, JsonLd } from "gatsby-plugin-next-seo";
import { Layout } from "@/components/layout";

const BlogDetailPage = ({ data }: any) => {
  const {
    frontmatter: {
      title,
      description,
      publishedOn,
      modifiedOn,
      tags,
      author,
      embeddedImagesLocal,
    },
    slug,
    body,
  } = data.mdx;
  return (
    <>
      <GatsbySeo
        title={title}
        description={description}
        canonical={`https://www.tectratechnologies.com/${slug}/`}
        openGraph={{
          locale: "en_US",
          type: "article",
          url: `https://www.tectratechnologies.com/${slug}/`,
          title: `${title}`,
          description: `${description}`,
          site_name: "Tectra Technologies",
          article: {
            publishedTime: `${publishedOn}`,
            modifiedTime: `${modifiedOn}`,
            tags: tags,
          },
        }}
        twitter={{
          handle: "@handle",
          site: "@TectraL",
          cardType: "summary_large_image",
        }}
      />
      <JsonLd
        json={{
          "@context": "https://schema.org",
          "@type": "WebSite",
          "@graph": [
            {
              "@type": "WebSite",
              "@id": "https://www.tectratechnologies.com/#website",
              url: "https://www.tectratechnologies.com/",
              name: "Tectra Technologies",
              description:
                "Web &amp; Mobile App, Cloud, UI/UX, Devops, Tax Services, Digital Marketing",
              potentialAction: [
                {
                  "@type": "SearchAction",
                  target: {
                    "@type": "EntryPoint",
                    urlTemplate:
                      "https://www.tectratechnologies.com/?s={search_term_string}",
                  },
                  "query-input": "required name=search_term_string",
                },
              ],
              inLanguage: "en-US",
            },
          ],
        }}
      />
      <Layout>
        <div className="bg-white mt-28 lg:mt-32">
          <div className="max-w-7xl mx-auto px-5 lg:px-8">
            <h2 className="text-black text-left text-3xl font-poppins font-semibold captalize tracking-tight">
              {title}
            </h2>
            <p className="mt-2 text-sm text-black font-poppons font-thin">
              By <span>{author}</span> | <span>{publishedOn}</span> |{" "}
              {tags.toString()}
            </p>
            <GatsbyImage
              alt=""
              image={getImage(embeddedImagesLocal[0]) as any}
              className="w-full mt-6"
            />
            <div className="mt-6">
              <MDXProvider components={(<></>) as any}>
                <MDXRenderer localImages={embeddedImagesLocal}>
                  {body}
                </MDXRenderer>
              </MDXProvider>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

export const query = graphql`
  query ($slug: String) {
    mdx(fileAbsolutePath: { regex: "/data/blogs/" }, slug: { eq: $slug }) {
      frontmatter {
        title
        description
        publishedOn(formatString: "MMMM D, YYYY")
        modifiedOn(formatString: "MMMM D, YYYY")
        tags
        author
        embeddedImagesLocal {
          childImageSharp {
            gatsbyImageData(height: 500)
          }
        }
      }
      body
    }
  }
`;

export default BlogDetailPage;
